<template>
  <el-row class="displays">
    <el-col :span="24">
      <div class="pages hidden-xs-only">
        <div class="banner">
          <img src="@/assets/images/accunt/hc1.png" alt="" />
          <div class="title" :class="{ weibei: lang }">
            {{ $t("conference").title1 }}
          </div>
        </div>
        <div class="textConter" v-if="0">
          <div class="conter1">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("conference").t1 }}
            </div>
            <p style="margin-bottom: 0px">
              {{ $t("conference").t2 }}
            </p>
            <p>
              {{ $t("conference").t3 }}
            </p>
            <p>
              {{ $t("conference").t4 }}
            </p>
            <p>
              {{ $t("conference").t5
              }}<a :href="'https://' + $t('conference').t6" target="_blank">{{
                $t("conference").t6
              }}</a>
            </p>
            <div class="imgs">
              <img src="@/assets/images/accunt/hc2.png" alt="" />
            </div>
            <div class="imgs">
              <img src="@/assets/images/accunt/hc3.png" alt="" />
            </div>
          </div>
          <div class="conter2">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("conference").changdi }}
            </div>
            <div class="address">
              <p>
                <span class="title_a">{{ $t("conference").c1 }}</span>
                <span>{{ $t("conference").c2 }}</span>
              </p>
              <p>
                <span class="title_a">{{ $t("conference").c3 }}</span>
                <span>{{ $t("conference").c4 }}</span>
              </p>
            </div>
            <div class="addressImg">
              <img src="@/assets/images/accunt/address.png" alt="" />
            </div>
          </div>
          <div class="conter3">
            <div class="left">
              <div class="title" :class="{ weibei: lang }">
                {{ $t("conference").jianjie }}
              </div>
              <p>
                {{ $t("conference").j1 }}
              </p>
              <div class="dataItem">{{ $t("conference").j2 }}</div>
              <div class="dataItem">{{ $t("conference").j3 }}</div>
              <div class="dataItem">{{ $t("conference").j4 }}</div>
              <div class="dataItem">{{ $t("conference").j5 }}</div>
              <div class="dataItem">{{ $t("conference").j6 }}</div>
              <div class="dataItem">{{ $t("conference").j7 }}</div>
              <div class="dataItem">{{ $t("conference").j8 }}</div>
              <p style="margin-top: 20px">
                {{ $t("conference").j10 }}
              </p>
            </div>
            <div class="right">
              <img src="@/assets/images//accunt/jj.png" alt="" />
            </div>
            <div class="textLast">
              {{ $t("conference").j11 }}
            </div>
          </div>
          <div class="conter4">
            <div class="left">
              <div class="title" :class="{ weibei: lang }">
                {{ $t("conference").tianqi }}
              </div>
              <div class="text">
                {{ $t("conference").q1 }}
              </div>
            </div>
            <div class="right">
              <img src="@/assets/images/accunt/tq.png" alt="" />
            </div>
          </div>
        </div>
        <div class="renList">
          <div class="name">{{$t('hzhb').t1}}</div>
          <div class="display">
            <div class="one">
              <div class="title">{{$t('hzhb').t2}}</div>
              <div class="list">
                <div class="item" v-for="(item, index) in $t('hzhb').list" :key="index">
                  <div><span class="index">{{ index + 1 }} . </span> {{ item }}</div>
                </div>
              </div>
            </div>
            <div class="one">
              <div class="title">{{$t('hzhb').t3}}</div>
              <div class="list">
                <div class="item" v-for="(item, index) in $t('hzhb').list1" :key="index">
                   <div><span class="index">{{ index + 1 }} . </span> {{ item }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pages1 hidden-sm-and-up">
        <div class="banner">
          <img src="@/assets/images/accunt/hc11.png" alt="" />
          <div class="title" :class="{ weibei: lang }">
            {{ $t("conference").title1 }}
          </div>
        </div>
        <div class="textConter" v-if="0">
          <div class="conter1">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("conference").t1 }}
            </div>
            <p style="margin-bottom: 0px">
              {{ $t("conference").t2 }}
            </p>
            <p>
              {{ $t("conference").t3 }}
            </p>
            <p>
              {{ $t("conference").t4 }}
            </p>
            <p>
              {{ $t("conference").t5
              }}<a :href="'https://' + $t('conference').t6" target="_blank">{{
                $t("conference").t6
              }}</a>
            </p>
            <div class="imgs">
              <img src="@/assets/images/accunt/hc2.png" alt="" />
            </div>
            <div class="imgs">
              <img src="@/assets/images/accunt/hc3.png" alt="" />
            </div>
          </div>
          <div class="conter2">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("conference").changdi }}
            </div>
            <div class="address">
              <p>
                <span class="title_a">{{ $t("conference").c1 }}</span>
                <span>{{ $t("conference").c2 }}</span>
              </p>
              <p>
                <span class="title_a">{{ $t("conference").c3 }}</span>
                <span>{{ $t("conference").c4 }}</span>
              </p>
            </div>
            <div class="addressImg">
              <img src="@/assets/images/accunt/address.png" alt="" />
            </div>
          </div>
          <div class="conter3">
            <div class="left">
              <div class="title" :class="{ weibei: lang }">
                {{ $t("conference").jianjie }}
              </div>
              <p>
                {{ $t("conference").j1 }}
              </p>
              <div class="dataItem">{{ $t("conference").j2 }}</div>
              <div class="dataItem">{{ $t("conference").j3 }}</div>
              <div class="dataItem">{{ $t("conference").j4 }}</div>
              <div class="dataItem">{{ $t("conference").j5 }}</div>
              <div class="dataItem">{{ $t("conference").j6 }}</div>
              <div class="dataItem">{{ $t("conference").j7 }}</div>
              <div class="dataItem">{{ $t("conference").j8 }}</div>
              <p style="margin-top: 20px">
                {{ $t("conference").j10 }}
              </p>
            </div>
            <div class="textLast">
              {{ $t("conference").j11 }}
            </div>
            <div class="right">
              <img src="@/assets/images//accunt/jj.png" alt="" />
            </div>
          </div>
          <div class="conter4">
            <div class="left">
              <div class="title" :class="{ weibei: lang }">
                {{ $t("conference").tianqi }}
              </div>
              <div class="text">
                {{ $t("conference").q1 }}
              </div>
            </div>
            <div class="right">
              <img src="@/assets/images/accunt/tq.png" alt="" />
            </div>
          </div>
        </div>
        <div class="renList">
          <div class="name">{{$t('hzhb').t1}}</div>
          <div class="display">
            <div class="one">
              <div class="title">{{$t('hzhb').t2}}</div>
              <div class="list">
                <div class="item" v-for="(item, index) in $t('hzhb').list" :key="index">
                  <div><span class="index">{{ index + 1 }} . </span> {{ item }}</div>
                </div>
              </div>
            </div>
            <div class="one">
              <div class="title">{{$t('hzhb').t3}}</div>
              <div class="list">
                <div class="item" v-for="(item, index) in $t('hzhb').list1" :key="index">
                   <div><span class="index">{{ index + 1 }} . </span> {{ item }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      list: [
        `国家林业和草原局`,
        `国际竹藤中心`,
        `海关总署关税征管司`,
        `中国常驻世界贸易组织代表团`,
        `国家林业和草原局竹林碳汇工程技术研究中心`,
        `国家林业和草原局竹子研究开发中心`,
        `国际竹藤中心绿色经济研究所`,
        `国际竹藤中心园林花卉与景观研究所`,
        `国际竹藤中心竹类与花卉国家林木种质资源保存库`,
        `国际竹藤中心竹藤生物质新材料研究所`,
        `国际竹藤中心竹藤资源化学利用研究所`,
        `国际竹藤中心竹藤资源基因科学与基因产业化研究所`,
        `国际竹藤中心竹藤资源与环境研究所`,
        `国际竹藤中心三亚研究基地`,
        `海南三亚竹藤伴生林生态系统国家定位观测研究站`,
        `中加竹藤科学与技术联合实验室`,
        `中国科学院东南亚生物多样性研究中心`,
        `中国科学院西双版纳热带植物园`,
        `中国林业科学研究院林产化学工业研究所`,
        `中国林业科学研究院木材工业研究所`,
        `中国林业科学研究院亚热带林业研究所`,
        `山地未来研究中心`,
        `福建省林业科学研究院`,
        `福建省协同创新院竹产业（建瓯）分院`,
        `河北省活性炭产业技术研究院`,
        `上海市建筑科学研究院`,
        `省部共建亚热带森林培育国家重点实验室`,
        `浙江省林业科学研究院（笋竹产业国家创新联盟）`,
        `竹子培育与利用浙江国际科技合作基地`,
        `永安市竹产业研究院`,
        `福建永安竹林生态系统国家定位观测研究站`,
        `中国竹产业协会`,
        `中国竹产业协会竹产业装备分会`,
        `中国竹产业协会竹食品和日用品分会`,
        `中国竹产业协会竹炭分会`,
        `中国生态文化协会`,
        `中国花卉协会`,
        `中国林学会`,
        `中国林学会竹藤资源利用分会`,
        `中国林学会竹子分会`,
        `中国竹藤品牌集群`,
        `竹类种质资源保护与利用国家创新联盟`,
        `竹藤产业国家创新联盟`,
        `香港建筑师学会`,
        `江苏省农业绿色发展研究会`,
        `江苏省生态学会`,
        `江苏省系统工程学会`,
        `浙江省竹产业协会`,
        `安吉县竹产业协会`,
        `杭州市余杭区竹业协会（全国中小径竹笋产业服务站）`,
        `北京林业大学`,
        `北京林业大学园林学院`,
        `南京林业大学`,
        `南京林业大学生物质材料国家工程研究中心`,
        `西南林业大学`,
        `浙江农林大学`,
        `浙江农林大学竹子研究院`,
        `同济大学`,
        `江西农业大学`,
        `四川农业大学`,
        `西南财经大学`,
        `清华大学建筑学院`,
        `清华大学土木水利学院`,
        `香港大学建筑学院`,
        `浙江大学建筑工程学院`,
        `浙江大学-伊利诺伊大学厄巴纳香槟校区联合学院生物质材料与碳中和联合研究中心`,
        `重庆大学建筑城规学院`,
        `东南大学土木工程学院`,
        `赣南师范大学江西竹基新材料工程中心`,
        `南京工业大学土木工程学院`,
        `福建省永安市人民政府`,
        `四川省眉山市人民政府`,
        `四川省邛崃市人民政府`,
        `重庆市武隆区人民政府`,
        `湖南省桃江县人民政府`,
        `江西省资溪县人民政府`,
        `浙江省安吉县人民政府`,
        `浙江省庆元县人民政府`,
        `中国对外书刊出版发行中心（国际传播发展中心）`,
        `《世界竹藤通讯》杂志`,
        `《建筑结构》杂志`,
        `《可持续结构》杂志`,
        `《风景园林》杂志`,
        `遂昌竹炭博物馆`,
        `清华大学建筑设计研究院有限公司`,
        `国家林草装备科技创新园`,
        `安徽炬能炭业有限公司`,
        `安徽长思机械设备科技有限公司`,
        `安吉竹境竹业科技有限公司`,
        `承德绿世界活性炭有限公司`,
        `滁州市昌春木炭专业合作社`,
        `东莞市嘉航实业有限公司`,
        `福建明良食品有限公司`,
        `赣州森泰竹木有限公司`,
        `广东裕丰竹纤维有限公司`,
        `广东竹康材料科技有限公司`,
        `杭州邦博科技有限公司`,
        `河北吉藁化纤有限责任公司`,
        `洪雅竹元科技有限公司`,
        `湖南桃花江竹材科技股份有限公司`,
        `江苏见竹绿建竹材科技股份有限公司`,
        `江苏金梧机械有限公司`,
        `奇竹（北京）科技发展有限公司`,
        `深圳金色盆地科技有限公司`,
        `天竹联盟`,
        `熊猫碳业科技有限公司`,
        `浙江富来森竹炭有限公司`,
        `浙江佶竹生物科技有限公司`,
        `浙江圣氏生物科技有限公司`,
        `中国建筑西南设计研究院有限公司`,
        `重庆瑞竹植物纤维制品有限公司`,
        `重庆特珍食品有限公司`,
        `竹纤维产业网
`,
      ],
      list1: [
        `联合国工发组织`,
        `联合国教科文组织驻华代表处`,
        `联合国粮农组织`,
        `联合国人区署`,
        `联合国贸易和发展大会`,
        `联合国森林论坛秘书处`,
        `联合国环境规划署世界保护监测中心`,
        `世界自然基金会（瑞士）北京代表处`,
        `国际标准化组织竹藤技术委员会秘书处`,
        `国际林联竹藤工作组`,
        `国际山地综合发展中心`,
        `国际竹藤组织全球竹建筑专家组`,
        `非洲联盟委员会/非洲联盟发展机构-非洲发展新伙伴计划`,
        `西班牙国际发展合作署`,
        `厄瓜多尔城市发展和住房部`,
        `加纳林业研究所`,
        `秘鲁国家森林和野生动物管理局`,
        `西班牙/葡萄牙--伊比利亚-美洲竹子协会`,
        `阿肯顿·阿皮亚·门卡大学`,
        `迪肯大学`,
        `考文垂大学`,
        `匹兹堡大学`,
        `万隆理工学院`,
        `西英格兰大学`,
        `亚的斯亚贝巴大学`,
        `加拿大不列颠哥伦比亚大学`,
        `苏黎世联邦理工学院`,
        `弗吉尼亚理工大学设计学院`,
        `国际金融公司`,
        `Bambusa工作室（西班牙）
`,
      ],
    };
  },
  mounted() {},
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  methods: {},
};
</script>
<style lang="stylus" scoped>
.displays {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Pageconter {
  margin: auto;
}

.pages {
  width: 100%;

  .banner {
    width: 100%;
    background: #405E3C;
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -130px;
      margin-top: -40px;
      width: 260px;
      height: 80px;
      background: rgba(141, 198, 63, 0.9);
      text-align: center;
      line-height: 80px;
      font-weight: 700;
      font-size: 42px;
      color: #FFFFFF;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  .renList {
    max-width: 1400px;
    margin: auto;
    padding: 30px 0;

    .name {
      color: #196BA2;
      font-size: 25px;
      font-weight: bold;
      font-family: weibei;
      margin-bottom: 30px;
    }

    .display {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .one {
        font-size: 14px;
        width: 45%;

        .title {
          background: #22B5A9;
          color: #fff;
          font-family: weibei;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
        }

        .list {
          .item {
            height: 40px;
            display: flex;
            align-items: center;
            text-align: left;
            padding: 0 40px 0 65px;
            box-sizing: border-box;
            div{
              flex: 1
              position relative;
              .index{
                position absolute
                top 0
                left:  -30px
              }
            }

            &:nth-child(2n) {
              background: #E4F0D4;
            }
          }
        }
      }
    }
  }

  .textConter {
    width: 100%;
    max-width: 1520px;
    margin: 69px auto 97px auto;
    padding: 0 60px;
    box-sizing: border-box;

    .conter1 {
      .title {
        font-weight: 700;
        font-size: 28px;
        color: #405E3C;
        text-align: left;
        margin-bottom: 37px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        text-align: left;
        margin-bottom: 30px;

        a {
          color: #1890FF;
          font-weight: bold;
        }
      }

      .imgs {
        margin-bottom: 12px;
        width: 100%;

        img {
          display: block;
          width: 100%;
        }
      }
    }

    .conter2 {
      margin-top: 95px;

      .title {
        font-weight: 700;
        font-size: 28px;
        color: #405E3C;
        text-align: left;
        margin-bottom: 30px;
      }

      .address {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        p {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          font-weight: 700;
          font-size: 16px;
          margin-right: 84px;
          text-align: left;

          .title_a {
            color: #8DC63F;
            margin-right: 4px;
          }
        }
      }

      .addressImg {
        max-width: 818px;
        margin: auto;
        margin-top: 39px;

        img {
          width: 100%;
        }
      }
    }

    .conter3 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: space-between;
      text-align: left;
      margin-top: 168px;

      .left {
        width: 673px;

        .title {
          font-weight: 700;
          font-size: 28px;
          color: #405E3C;
          margin-bottom: 48px;
        }

        p {
          font-size: 16px;
          color: #333333;
          margin-bottom: 20px;
          line-height: 24px;
        }

        .dataItem {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .right {
        width: 590px;

        img {
          width: 100%;
        }
      }

      .textLast {
        font-size: 16px;
        color: #333333;
        line-height: 24px;
      }
    }

    .conter4 {
      margin-top: 80px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .right {
        width: 664px;
      }

      .left {
        flex: 1;
        margin-right: 50px;
        text-align: left;

        .title {
          font-weight: 700;
          font-size: 28px;
          color: #405E3C;
          margin-top: 20px;
        }

        .text {
          font-size: 16px;
          line-height: 24px;
          color: #333333;
          margin-top: 70px;
        }
      }
    }
  }
}

.pages1 {
  width: 100%;

  .banner {
    width: 100%;
    height: 190px;
    background: #405E3C;
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -60px;
      margin-top: -25px;
      width: 120px;
      height: 50px;
      background: rgba(141, 198, 63, 0.9);
      text-align: center;
      line-height: 50px;
      font-weight: 700;
      font-size: 16px;
      color: #FFFFFF;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .renList {
    width: 100%;
    padding: 20px 10px;
    box-sizing: border-box;

    .name {
      color: #196BA2;
      font-size: 20px;
      font-weight: bold;
      font-family: weibei;
      margin-bottom: 20px;
    }

    .display {
      .one {
        font-size: 12px;
        width: 100%;

        .title {
          background: #22B5A9;
          color: #fff;
          font-family: weibei;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
        }

        .list {
          .item {
            height: 40px;
            display: flex;
            align-items: center;
            text-align: left;
            padding: 0 10px 0 30px;
            box-sizing: border-box;
            div{
              flex: 1
              position relative;
              .index{
                position absolute
                top 0
                left:  -20px
              }
            }
            &:nth-child(2n) {
              background: #E4F0D4;
            }
          }
        }
      }
    }
  }

  .textConter {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 50px;
    padding: 0 15px;
    box-sizing: border-box;

    .conter1 {
      .title {
        font-weight: 700;
        font-size: 20px;
        color: #405E3C;
        text-align: left;
        margin-bottom: 20px;
      }

      p {
        font-size: 14px;
        line-height: 21px;
        color: #333333;
        text-align: left;
        margin-bottom: 10px;

        a {
          color: #1890FF;
          font-weight: bold;
        }
      }

      .imgs {
        margin-bottom: 12px;
        width: 100%;

        img {
          display: block;
          width: 100%;
        }
      }
    }

    .conter2 {
      margin-top: 40px;

      .title {
        font-weight: 700;
        font-size: 20px;
        color: #405E3C;
        text-align: left;
        margin-bottom: 20px;
      }

      .address {
        p {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 6px;
          text-align: left;

          .title_a {
            color: #8DC63F;
            margin-right: 4px;
          }
        }
      }

      .addressImg {
        width: 100%;
        margin: auto;
        margin-top: 30px;

        img {
          width: 100%;
        }
      }
    }

    .conter3 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: space-between;
      text-align: left;
      margin-top: 40px;

      .left {
        .title {
          font-weight: 700;
          font-size: 20px;
          color: #405E3C;
          margin-bottom: 20px;
        }

        p {
          font-size: 14px;
          color: #333333;
          margin-bottom: 20px;
          line-height: 21px;
        }

        .dataItem {
          font-size: 14px;
          line-height: 21px;
        }
      }

      .right {
        img {
          width: 100%;
        }
      }

      .textLast {
        font-size: 14px;
        color: #333333;
        line-height: 21px;
        margin-bottom: 20px;
      }
    }

    .conter4 {
      margin-top: 40px;

      .right {
        width: 100%;
        margin-top: 20px;

        img {
          width: 100%;
        }
      }

      .left {
        flex: 1;
        text-align: left;

        .title {
          font-weight: 700;
          font-size: 20px;
          color: #405E3C;
        }

        .text {
          font-size: 14px;
          line-height: 21px;
          color: #333333;
          margin-top: 15px;
        }
      }
    }
  }
}
</style>